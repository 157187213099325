<template>
  <MainLayout fluid header-class="bg-info">
    <h1>
      <i class='bx bx-grid' />
      <span class="ml-1">{{$t('lockers.title')}}</span>
    </h1>

    <form-input
      type="item" 
      icon="bx-map" 
      size="xl"
      v-model="selectedLocker"
      resource-name="locations"
      :scope="locationScope"
      :clearable="false"
      :placeholder="$t('lockers.location-placeholder')"
      @input="getPackages"
    />

    <div>
      <b-overlay
        :show="loading"
        rounded
        spinner-type="grow"
        spinner-variant="primary"
        style="min-height: 90%;"
      >
  
      <template v-if="selectedLocker && packages.length">

        <b-button-toolbar class="justify-content-between">

          <b-button-group class="mx-1">
            <async-button variant="info" :handler="expireAll">
              {{ $t('lockers.expire-all') }}
            </async-button>
          </b-button-group>

        </b-button-toolbar>

        <hr class="my-4">

        <h3 v-if="packages.length" class="mb-4">
          <i class='bx bx-package'></i> 
          {{$t('stores.packages')}}
          <span class="text-muted">({{packages.length}})</span>
        </h3>

        <b-container fluid class="p-0">
          <b-row>
            <b-col v-for="item in packages" :key="item.id" cols="12" sm="6" lg="6" xl="4">
              <PackageInfo v-bind="{item}" @click="openPackage(item)" detail="expiry" locker-numbers />
            </b-col>
          </b-row>
        </b-container>
      </template>
      <div v-else-if="selectedLocker && !loading" class="text-center my-5">
        {{ $t('lockers.no-packages') }}
      </div>

  
      </b-overlay>

    </div>

  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import api from '@/api';
import Package from '@/resources/Package';
// import { FEATURE_FETCHING } from '@/resources/Merchant';
import { mapGetters } from 'vuex';
import PackageInfo from "@/components/PackageInfo.vue";
import AsyncButton from "@/components/AsyncButton.vue";
// import { vibrate } from '@/utils';
import FormInput from "@/components/Form/Input";
import LockerModal from '@/components/Modals/Locker.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Driver',
  components: {
    MainLayout,
    FormInput,
    PackageInfo,
    AsyncButton
  },
  data() {
    return {
      loading: false,
      packages: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    ...mapFields('locations',[
      'selectedLocker'
    ]),
    locationScope() {
      return [
        'dropoff',
        'active',
        'country',
        ['class', 'locker']
      ]
    },
  },
  created() {
    if(this.selectedLocker) {
      this.getPackages()
    }
  },
  methods: {
    async getPackages() {
      this.loading = true

      try {
        const { data: { data: packages} } = await api.get(`locations/${this.selectedLocker}/packages`)

        packages.sort((a, b) => a.locker_number.localeCompare(b.locker_number, undefined,{ numeric: true }))

        this.packages = packages
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async openPackage({ id }) {
      const item = await Package.get(id)

      const response = await this.$modal(LockerModal,{ item })

      response?.reload && this.getPackages()
    },
    async expireAll() {
      const confirm = await this.$bvModal.msgBoxConfirm(this.$t('lockers.expire-all-confirm-text'),{
        cancelTitle: this.$t('common.cancel'),
        okTitle: this.$t('packages.actions.expire'),
        okVariant: 'danger',
      })

      if(confirm) {
        this.loading = true
        try {
          await api.patch(`locations/${this.selectedLocker}/expire-all`)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>